<template>
  <div>
    <!--closed-->
    <v-card
        outlined
        class="ma-5"
        v-if="mode==='closed'"
    >
      <div class="checkout__section_step-num">
        2
        <div class="checkout__section_step-arrow inactive"></div>
      </div>
      <div class="checkout__section_header"> <h5>{{ $t('checkout.paymentTitle') }}</h5> </div>
    </v-card>
    <!-- end closed-->
    <!-- open -->
    <v-card
        outlined
        class="ma-5"
        v-if="mode==='open'"
    >
      <div class="checkout__section_step-num">
        2
        <div class="checkout__section_step-arrow"></div>
      </div>
      <div class="checkout__section_header"><h5>{{ $t('checkout.paymentTitle') }}</h5> </div>
      <v-container class="pl-sm-2 pl-lg-10">
        <v-card-text>
          <StripeElementCard
              ref="cardElement"
              style="max-height: 100px;"
              class="mb-lg-10 mb-5 input__field rounded-0 text-h4"
              :pk="stripeKey"
              @token="tokenCreated"
              :tokenData="tokenData"
          />
          Your credit and debit cards
          <v-row>
            <v-col></v-col>
            <v-col>Name on card</v-col>
            <v-col>Expires</v-col>
          </v-row>
          <v-radio-group v-model="id">
            <v-radio class="pb-5" :value="source.id" v-for="(source, k) in sources" :key="k">
              <template v-slot:label>
                <v-row style="border-bottom: 1px solid #e0e2e5;">
                  <v-col>{{ source.funding }} card ending in {{ source.last4}}</v-col>
                  <v-col>{{ source.name }}</v-col>
                  <v-col>
                    {{ source.exp_month }}/{{ source.exp_year }}

                  </v-col>
                </v-row>
              </template>
            </v-radio>
            <v-radio value="new">
              <template v-slot:label>
                <v-row>
                  <v-col>Add Credit or Debit Card</v-col>
                </v-row>
              </template>
            </v-radio>
          </v-radio-group>
          <div v-if="id === 'new'">
            <StripeElementCard
                ref="cardElement"
                style="max-height: 100px;"
                class="mb-lg-10 mb-5 input__field rounded-0 text-h4"
                :pk="stripeKey"
                @token="tokenCreated"
                :tokenData="tokenData"
            />

            <v-text-field
                :label="$t('checkout.cardName')"
                required
                :rules="[value => !!value || $t('checkout.validation.cardName')]"
                v-model="tokenData.name"
            />

            <div class="pt-5">Billing Address</div>
            <div>
              <v-checkbox label="Use my shipping address" />
              <div class="pl-8">
                <div>{{ shippingAddress.address1 }} {{ shippingAddress.address2 }}</div>
                <div>{{ shippingAddress.city }}, {{ shippingAddress.state }} {{ shippingAddress.zip }}</div>
                <div>United States</div>
              </div>
            </div>

            <AddressComponent />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
              type="submit"
              class="btn"
              color="primary"
              block
              :disabled="!isValid"
          >
            <span class="btn__text btn__text--white"> {{ $t('checkout.usePaymentMethod') }}</span>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <!-- end open-->
    <!-- complete -->
    <v-card
        outlined
        class="ma-5 checkout__section_complete"
        v-if="mode==='complete'"
    >
      <div class="checkout__section_step-num complete">
        <v-icon
            large
            color="white"
        >mdi-check-circle</v-icon>
        <div class="checkout__section_step-arrow complete"></div>
      </div>
      <div class="checkout__section_header">
        <v-row class="ma-0">
          <v-col cols="10" class="pa-0" style="height: 60px; line-height: 60px;"><h5>{{ $t('checkout.paymentTitle') }}</h5></v-col>
          <v-col cols="2" class="pa-0 ma-auto" style="text-align: center;">
            <a href="#" @click="$emit('edit')">Edit</a>
          </v-col>
        </v-row>
      </div>
      <v-container class="pl-sm-2 pl-lg-10">
        <div class="pl-lg-10">
          <div>
            Payment Method: {{ selected.brand }} {{ selected.funding }} ending in {{ selected.last4 }}
          </div>
          <div>
            Billing address: {{ selected.name }} {{ selected.address_line1 }}
          </div>
        </div>
      </v-container>
    </v-card>
    <!-- end complete -->
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import AddressComponent from '@/components/checkout/AddressComponent'

export default {
  name: 'PaymentComponent',
  components: {
    AddressComponent,
    StripeElementCard
  },
  props: {
    mode: {
      type: String,
      default: 'closed'
    },
    defaultPaymentType: {
      type: Object
    },
    stripeKey: {
      type: String,
      default: ''
    },
    shippingAddress: {
      type: Object
    },
    sources: {
      type: Object
    },
    selectedId: {
      type: String,
    }
  },
  data() {
    return {
      dialogCC: false,
      form: {},
      id: '',
      tokenData: {
        'address_line1': '',
        name: ''
      }
    }
  },
  mounted() {
    console.log(window.Stripe);

    // const stripe = window.Stripe(this.stripeKey);
    // console.log(stripe);

  },
  methods: {
    tokenCreated (token) {
      this.form.token = token;
      this.form.itemId = 'defaultBook';
    }
  },
  computed: {
    isValid() {
      return this.id !== '';
    },
    selected() {
      if (this.sources[this.selectedId]) {
        return this.sources[this.selectedId]
      } else {
        return {}
      }
    }
  },
  watch: {
    selectedId: {
      immediate: true,
      handler: function(selectedIdFromProps) {
        if (selectedIdFromProps) {
          this.id = selectedIdFromProps;
        }
      }
    }
  }
}

// <div v-if="defaultPaymentType.id">
//   Payment Method: {{ defaultPaymentType.funding }} ending in {{ defaultPaymentType.last4 }}
//   Billing address: {{ defaultPaymentType.name }} {{ defaultPaymentType.address_line1 }}
// </div>
</script>