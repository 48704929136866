<template>
  <div>
    <v-text-field
        :label="$t('address.address1') + '*'"
        v-model="form.address1"
        @change="onChange"
        :rules="[value => !!value || $t('checkout.validation.address1')]"
        required
    />
    <v-text-field
        :label="$t('address.address2')"
        v-model="form.address2"
        @change="onChange"
        required
    />
    <v-row>
      <v-col lg="4" cols="12">
        <v-text-field
            :label="$t('address.city') + '*'"
            v-model="form.city"
            :rules="[value => !!value || $t('checkout.validation.address1')]"
            @change="onChange"
            required
        />
      </v-col>
      <v-col lg="4" cols="12">
        <v-select
            :label="$t('address.state') + '*'"
            v-model="form.state"
            :items="states"
            @change="onChange"
            required
        />
      </v-col>
      <v-col lg="4" cols="12">
        <v-text-field
            :label="$t('address.zip') + '*'"
            v-model="form.zip"
            @change="onChange"
            :rules="[value => !!value || $t('checkout.validation.address1')]"
            required
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'AddressComponent',
  props: {
    address: {
      type: Object,
      default:  () => ({ address1: '', address2: '', city: '', state: '', zip: '' })
    },
    labels: {
      type: Object,
      default: () => ({
        address1: 'Address*',
        address2: 'Address 2'
      })
    }
  },
  data() {
    return {
      form: {},
      states: [
        {value: 'AL', text: 'Alabama'},
        {value: 'AK', text: 'Alaska'},
        {value: 'AS', text: 'American Samoa'},
        {value: 'AZ', text: 'Arizona'},
        {value: 'AR', text: 'Arkansas'},
        {value: 'CA', text: 'California'},
        {value: 'CO', text: 'Colorado'},
        {value: 'CT', text: 'Connecticut'},
        {value: 'DE', text: 'Delaware'},
        {value: 'DC', text: 'District of Columbia'},
        {value: 'FL', text: 'Florida'},
        {value: 'GA', text: 'Georgia'},
        {value: 'GU', text: 'Guam'},
        {value: 'HI', text: 'Hawaii'},
        {value: 'ID', text: 'Idaho'},
        {value: 'IL', text: 'Illinois'},
        {value: 'IN', text: 'Indiana'},
        {value: 'IA', text: 'Iowa'},
        {value: 'KS', text: 'Kansas'},
        {value: 'KY', text: 'Kentucky'},
        {value: 'LA', text: 'Louisiana'},
        {value: 'ME', text: 'Maine'},
        {value: 'MD', text: 'Maryland'},
        {value: 'MA', text: 'Massachusetts'},
        {value: 'MI', text: 'Michigan'},
        {value: 'MN', text: 'Minnesota'},
        {value: 'MS', text: 'Mississippi'},
        {value: 'MO', text: 'Missouri'},
        {value: 'MT', text: 'Montana'},
        {value: 'NE', text: 'Nebraska'},
        {value: 'NV', text: 'Nevada'},
        {value: 'NH', text: 'New Hampshire'},
        {value: 'NJ', text: 'New Jersey'},
        {value: 'NM', text: 'New Mexico'},
        {value: 'NY', text: 'New York'},
        {value: 'NC', text: 'North Carolina'},
        {value: 'ND', text: 'North Dakota'},
        {value: 'OH', text: 'Ohio'},
        {value: 'OK', text: 'Oklahoma'},
        {value: 'OR', text: 'Oregon'},
        {value: 'PA', text: 'Pennsylvania'},
        {value: 'PR', text: 'Puerto Rico'},
        {value: 'RI', text: 'Rhode Island'},
        {value: 'SC', text: 'South Carolina'},
        {value: 'SD', text: 'South Dakota'},
        {value: 'TN', text: 'Tennessee'},
        {value: 'TX', text: 'Texas'},
        {value: 'UT', text: 'Utah'},
        {value: 'VT', text: 'Vermont'},
        {value: 'VI', text: 'Virgin Islands'},
        {value: 'VA', text: 'Virginia'},
        {value: 'WA', text: 'Washington'},
        {value: 'WV', text: 'West Virginia'},
        {value: 'WI', text: 'Wisconsin'},
        {value: 'WY', text: 'Wyoming'},
        {value: 'AE', text: 'Armed Forces Africa'},
        {value: 'AA', text: 'Armed Forces America (exc. Canada)'},
        {value: 'AE', text: 'Armed Forces Canada'},
        {value: 'AE', text: 'Armed Forces Europe'},
        {value: 'AE', text: 'Armed Forces Middle East'},
        {value: 'AP', text: 'Armed Forces Pacific'}
      ]
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.form);
    }
  },
  watch: {
    address: {
      immediate: true,
      handler: function(addressFromProps){
        if (addressFromProps) {
          this.form = {
            ...addressFromProps
          }
        }
      }
    }
  }
}
</script>