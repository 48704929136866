<template>
  <v-container class="px-0" fluid>
    <v-container>
      <v-row class="d-flex align-center justify-center header">
        <v-col cols="auto">
          <h1 class="header__title"><h5>Secure Checkout</h5></h1>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="d-flex justify-center mb-15 ml-auto mr-auto" style="max-width: 1440px;" >
      <v-col cols="12" lg="8" md="8" class="flex-column">
        <ShippingComponent
          :mode="shippingMode"
          :shipping="shippingAddress"
          @change="onShippingChange"
          @edit="onEditShipping"
        />

        <PaymentComponent
          :mode="paymentMode"
          :stripeKey="stripeKey"
          :shippingAddress="shippingAddress"
          :selectedId="paymentSourceSelectedId"
          :sources="paymentSources"
          @edit="onEditPayment"
        />

        <OrderComponent
          :mode="orderMode"
        />
        <div class="pl-5 pb-5">
          By clicking “Submit Order,” I agree to the JamBooks Terms of Use.
        </div>
        <v-btn
            type="submit"
            class="btn"
            color="primary"
            block
            :disabled="!isReady"
        >
          <span class="btn__text btn__text--white"> {{ $t('checkout.submitOrder') }}</span>
        </v-btn>
      </v-col>

      <v-col cols="12" lg="4" md="4" style="position: relative;" >
        <div style="position:sticky; top:0;">
          <v-card
              outlined
              class="mr-5 mb-5 mt-5"
          >
            <v-card-text style="border-bottom: 1px solid #e0e2e5; height: 60px;">
              <p>Order Summary</p>
            </v-card-text>
            <v-card-text>
              Subtotal
            </v-card-text>
            <v-card-text>
              Est. Shipping and Handeling
            </v-card-text>
            <v-card-text>
              Est. Sales Tax
            </v-card-text>
            <v-card-title>
              Total
            </v-card-title>
          </v-card>

          <v-card
              flat
              class="mr-5 mb-5 mt-5"
          >
            <v-card-title style="border-bottom: 1px solid #e0e2e5;">
              <p>Item Summary</p>
            </v-card-title>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import ShippingComponent from '@/components/checkout/ShippingComponent';
import PaymentComponent from '@/components/checkout/PaymentComponent';
import OrderComponent from '@/components/checkout/OrderComponent';
import { getterNames } from '@/store/global';
import Http from '@/api/Http';

export default {
  name: 'Checkout',
  components: {
    OrderComponent,
    ShippingComponent,
    PaymentComponent
  },
  data() {
    return {
      shipping: { method: 'usps', address: {}},
      paymentSources: {},
      paymentSourceSelectedId: null,
      shippingAddress: {},
      shippingMode: 'complete',
      paymentMode: 'open',
      orderMode: 'closed',
      api: Http.use('data')
    }
  },
  created() {
    // const stripe = window.Stripe(this.stripeKey);
    // console.log(stripe);
    // load shipping
    // load payments
    this.api.get('/user/')
        .then(user => {
          this.shippingAddress = {
            firstName: user.firstName,
            lastName: user.lastName,
            address1: user.address,
            city: user.city,
            state: user.state,
            zip: user.zip,
            phone: user.phone
          }
          if (user.address && user.city && user.zip && user.state) {
            this.shippingMode = 'complete';
          }
          return this.api.get('/user/payments/types/')
        })
        .then(sreturn => {
          this.paymentSources = sreturn.sources;
          this.paymentSourceSelectedId = sreturn.defaultSource;
          if (this.paymentSourceSelectedId) {
            // this.paymentMode = 'complete';
          }

          if (this.paymentMode === 'complete' && this.shippingMode === 'complete') {
            this.orderMode = 'open';
          }
         })
        .catch(err => {
          console.log(err);
        })
  },
  methods: {
    onEditShipping() {
      this.shippingMode = 'open';
    },
    onEditPayment() {
      this.paymentMode = 'open';
    },
    onShippingChange(val) {
      this.shipping.address = val;
      this.shippingMode = 'complete';
      this.paymentMode = 'open';
    }
  },
  computed: {
    stripeKey () {
      return this.$store.getters[getterNames.getStripeKey];
    },
    isReady () {
      return this.shippingMode === 'complete' &&
          this.paymentMode === 'complete';
    }
  }
}
</script>