<template>
  <v-text-field
      :label="label"
      :rules="rules"
      v-model="model"
  />
</template>
<script>
import { getFormattedPhone } from '@/lib';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    rules: {
      types: Object
    }
  },
  computed: {
    model: {
      get() {
        return getFormattedPhone(this.value);
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  data () {
    return {}
  }
}
</script>
