<template>
  <div>
    <v-card
        outlined
        class="ma-5"
        v-if="mode==='closed'"
    >
      <div class="checkout__section_step-num">
        3
        <div class="checkout__section_step-arrow inactive"></div>
      </div>
      <div class="checkout__section_header"> <h5>{{ $t('checkout.orderTitle') }}</h5> </div>
    </v-card>

    <v-card
        outlined
        class="ma-5"
        v-if="mode==='open'"
    >
      <div class="checkout__section_step-num">
        3
        <div class="checkout__section_step-arrow"></div>
      </div>
      <div class="checkout__section_header"> <h5>{{ $t('checkout.orderTitle') }}</h5> </div>
      <v-card-text>
        <div>Choose a Delivery Method</div>
        <v-radio-group v-model="form.method">
          <v-radio value="usps">
            <template v-slot:label>
              <span style="font-size: 1.5rem; font-weight: bold">U.S. Standard: $0.00</span>
            </template>
          </v-radio>
          <div class="ml-8 pb-6">Currently 5-7 business days, unless other wise noted</div>
          <v-divider class="pb-6"></v-divider>
          <v-radio value="uspsexp">
            <template v-slot:label>
              <div>
                <span style="font-size: 1.5rem; font-weight: bold">U.S. 2-Day Express: $7.00</span>
              </div>
            </template>
          </v-radio>
          <div class="ml-8">2 business days if ordered by 10 AM Mountain</div>
        </v-radio-group>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>

    <v-card
        outlined
        class="ma-5"
        v-if="mode==='complete'"
    ></v-card>
  </div>
</template>

<script>

export default {
  name: 'OrderComponent',
  props: {
    mode: {
      type: String,
      default: 'closed'
    }
  },
  data() {
    return {
      form: {}
    }
  }
}

</script>