<template>
  <div>
    <v-card
        outlined
        class="ma-5"
        v-if="mode==='closed'"
    >
      <div class="checkout__section_step-num">
        1
        <div class="checkout__section_step-arrow inactive"></div>
      </div>
      <div class="checkout__section_header"> <h5>{{ $t('checkout.shippingTitle') }}</h5> </div>
    </v-card>

    <v-card
        outlined
        class="ma-5"
        v-if="mode==='open'"
    >
      <div class="checkout__section_step-num">1<div class="checkout__section_step-arrow"></div></div>
      <div class="checkout__section_header"><h5>{{ $t('checkout.shippingTitle') }}</h5> </div>
      <v-container class="pl-sm-2 pl-lg-10">
        <v-card-text>
          <p>Enter Your Shipping Address</p>
          * Required field<br/><br/>
          <v-row>
            <v-col lg="6" cols="12">
              <v-text-field
                  :label="$t('checkout.firstName')"
                  required
                  :rules="[value => !!value || $t('checkout.validation.firstName')]"
                  ref="firstName"
                  v-model="form.firstName"
              />
            </v-col>
            <v-col lg="6" cols="12">
              <v-text-field
                  :label="$t('checkout.lastName')"
                  required
                  :rules="[rules.required]"
                  ref="lastName"
                  v-model="form.lastName"
              />
            </v-col>
          </v-row>
          <AddressComponent
            :address="form"
            @change="onChange"
          />
          <PhoneFieldComponent
              :label="$t('checkout.phone')"
              v-model="form.phone"
              :rules="[value => validatePhone(value) || $t('checkout.phoneError')]"
              required
              ref="phone"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
              type="submit"
              class="btn"
              color="primary"
              block
              @click="onSubmit"
              :disabled="!isValid"
          >
            <span class="btn__text btn__text--white"> {{ $t('checkout.continueToPayment') }}</span>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>

    <v-card
        outlined
        class="ma-5 checkout__section_complete"
        v-if="mode==='complete'"
    >
      <div class="checkout__section_step-num complete">
        <v-icon
            large
            color="white"
        >mdi-check-circle</v-icon>
        <div class="checkout__section_step-arrow complete"></div>
      </div>
      <div class="checkout__section_header">
        <v-row class="ma-0">
          <v-col cols="10" class="pa-0" style="height: 60px; line-height: 60px;"><h5>{{ $t('checkout.shippingTitle') }}</h5></v-col>
          <v-col cols="2" class="pa-0 ma-auto" style="text-align: center;">
            <a href="#" @click="$emit('edit')">Edit</a>
          </v-col>
        </v-row>

      </div>
      <v-container class="pl-sm-2 pl-lg-10 ml-lg-10">
        <div class="pl-lg-10">
          <div>{{ form.firstName }} {{ form.lastName }}</div>
          <div>{{ form.address1 }} {{ form.address2 }}</div>
          <div>{{ form.city }}, {{ form.state }} {{ form.zip }}</div>
          <div>United States</div>
          <div>{{ getFormattedPhone(form.phone) }}</div>
        </div>
      </v-container>
    </v-card>

  </div>
</template>

<script>
import AddressComponent from '@/components/checkout/AddressComponent'
import { validatePhone, getFormattedPhone } from '@/lib/';
import PhoneFieldComponent from '@/components/form/PhoneFieldComponent'

export default {
  name: 'ShippingComponent',
  components: {
    PhoneFieldComponent,
    AddressComponent
  },
  props: {
    mode: {
      type: String,
      default: 'open',
      validator(value) {
        return ['open', 'closed', 'complete'].includes(value);
      }
    },
    shipping: {
      type: Object,
      default:  () => ({method: 'def', address: {state:'UT'} })
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || this.$t('checkout.required'),
      },
      form: {}
    }
  },
  methods: {
    validatePhone,
    getFormattedPhone,
    onChange(val) {
      this.form = {
        ...this.form,
        ...val
      }
    },
    onSubmit() {
      this.$emit('change', this.form);
    },
    onEditLink(comp) {
      if (comp === 'shipping') {
        this.shippingM
      }
    }
  },
  computed: {
    isValid() {
      let isValid = true;
      if (
          !this.form?.firstName ||
          !this.form?.lastName ||
          !this.form?.address1 ||
          !this.form?.city ||
          !this.form?.zip ||
          !this.form?.state ||
          !validatePhone(this.form?.phone)
      ) {
        isValid = false;
      }
      return isValid;
    }
  },
  watch: {
    shipping: {
      immediate: true,
      handler: function(shippingFromProps) {
        if (shippingFromProps) {
          this.form = {
            ...shippingFromProps
          }
        }
      }
    }
  }
}
</script>